@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variables.scss';

.traffic {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding: 100px 10px 0 10px;

  @include respond-to(720px) {
    padding-top: 0;
  }
}

.traffic_title {
  @include mediumText(40px, 56px);
  margin-bottom: 72px;

  @include respond-to(980px) {
    display: none;
  }
}

.traffic_content {
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1264px;
  min-height: 516px;
  margin: 0 auto;
  background-color: $default-background-color;

  @include respond-to(980px) {
    flex-direction: column;
    background-color: #fefcfe;
  }
}

.traffic_content_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px;

  @include respond-to(768px) {
    padding: 0 10px;
  }
}

.traffic_content_block__title {
  @include mediumText(40px, 40px);
  max-width: 250px;
  min-height: 80px;
  margin: 0;

  @include respond-to(1048px) {
    @include mediumText(30px, 30px);
  }

  @include respond-to(1048px) {
    max-width: 100%;
  }

  @include respond-to(980px) {
    min-height: fit-content;
  }
}

.cards {
  display: flex;
  width: 100%;
  max-width: 488px;
  margin-top: 32px;
  margin-bottom: 64px;

  @include respond-to(980px) {
    max-width: 100%;
  }

  @include respond-to(768px) {
    margin-bottom: 48px;
  }

  @include respond-to(512px) {
    margin-bottom: 24px;
  }
}

.cards_element {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: $default-text-white-color;

  @include respond-to(980px) {
    background-color: $default-background-color;
  }
}

.block_text {
  padding: 20px 40px 20px 10px;
  margin-left: 20px;

  @include respond-to(1200px) {
    padding: 28px 35px 28px 35px;
  }

  @include respond-to(980px) {
    padding-right: 20px;
    padding-left: 0;
    width: 100%;
  }
}

.block_image {
  padding: 21px;
}

.blue_button {
  @include defaultStyleButton;
  @include boldText(14px, 17px);
  max-width: fit-content;

  @include respond-to(980px) {
    max-width: 100%;
  }

  @include respond-to(720px) {
    width: 100%;
    text-align: center;
  }
}

.yellow_button {
  @include defaultStyleButton;
  @include boldText(14px, 18px);
  background-color: #ffc700;
  font-size: 14px;
  color: $default-text-black-color;
  max-width: 220px;

  @include respond-to(980px) {
    max-width: 100%;
  }

  @include respond-to(720px) {
    width: 100%;
    text-align: center;
  }
}

.right {
  flex-grow: 1;
  /* border-left: 2px solid #e8eaec; */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @include respond-to(980px) {
    background-color: #fefcfe;
    border: none;
    width: 80%;
    border-radius: 0;
    padding-top: 0;
  }

  @include respond-to(768px) {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
    height: 320px;
    margin-bottom: 24px;
  }

  @include respond-to(512px) {
    width: 95%;
    margin-bottom: 0;
  }
}

.left {
  flex-grow: 1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @include respond-to(980px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 0;
    background-color: #fefcfe;
    width: 80%;
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  @include respond-to(768px) {
    width: 80%;
    height: 320px;
  }

  @include respond-to(512px) {
    width: 95%;
  }
}

.price {
  display: flex;
  flex-direction: column;
  background-color: $default-text-white-color;
  border-radius: 18px;
  padding: 29px 35px 28px 35px;
  width: calc(100% - 70px);
  max-width: 468px;
  margin-bottom: 64px;
  margin-top: 32px;

  @include respond-to(980px) {
    background-color: $default-background-color;
    max-width: 100%;
  }

  @include respond-to(768px) {
    margin-bottom: 48px;
  }

  @include respond-to(512px) {
    margin-bottom: 24px;
  }

  @include respond-to(720px) {
    padding: 24px 16px 24px 16px;
    width: calc(100% - 36px);
  }
}

.price_cost {
  & :nth-child(1) {
    @include mediumText(40px, 56px);

    @include respond-to(768px) {
      display: inline-block;
      @include mediumText(24px, 34px);
      margin-bottom: 10px;
    }
  }

  & :nth-child(2) {
    @include lightText(14px, 20px);

    @include respond-to(768px) {
      @include lightText(11px, 15px);
    }
  }
}

.price_rule {
  @include lightText(14px, 20px);
  color: rgba(156, 156, 156, 1);

  @include respond-to(1200px) {
    @include lightText(10px, 15px);
  }
}

.traffic_offer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $default-background-color;
  width: calc(100% - 142px);
  max-width: 1264px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 36px;
  padding: 42px 72px;

  @include respond-to(980px) {
    flex-direction: column;
    width: calc(75% - 105px);
  }

  @include respond-to(768px) {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include respond-to(512px) {
    width: 95%;
  }
}

.traffic_offer_title {
  @include mediumText(26px, 37px);
  margin-top: 16px;
  margin-bottom: 24px;
  @include respond-to(1048px) {
    @include mediumText(20px, 30px);
  }

  @include respond-to(768px) {
    @include mediumText(24px, 34px);
  }
}

.traffic_offer_text {
  @include lightText(18px, 25px);
  max-width: 604px;
  text-align: end;
  margin-top: 0;
  margin-bottom: 16px;
  @include respond-to(1048px) {
    @include lightText(14px, 20px);
  }

  @include respond-to(980px) {
    text-align: center;
  }

  @include respond-to(768px) {
    padding: 0 20px;
  }
}

.eateryClub {
  @include respond-to(720px) {
    width: 58px;
    height: 54px;
  }
}

.eateryClub_text.eateryClub_text {
  padding-left: 20px;
  padding-right: 0;
  @include mediumText(14px, 20px);

  @include respond-to(720px) {
    @include mediumText(12px, 16px);
  }
}

.link_blue {
  @extend .blue_button;

  @include respond-to(720px) {
    width: calc(100% - 83px);
  }
}

.link_yellow {
  @extend .yellow_button;

  @include respond-to(720px) {
    width: calc(100% - 83px);
  }
}
